import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./app.component";
import EditBookingCancel from "./cancel-booking/cancel-booking";
import EditBooking from "./edit-booking/edit-booking";
import Success from "./insurance-flow/insurance-flow-success.component";
import InsuranceFlow from "./insurance-flow/insurance-flow.component";

import Auth from "./auth/auth.component";
import EmailAuth from "./auth/email-routes/email-routes-auth.component";
import Logout from "./auth/logout.component";
import CancelBookingSuccess from "./cancel-booking/cancel-booking-success";
import OtherConditionEnd from "./other-condition-end/other-condition-end";
import PatientProgramSuccess from "./patient-program-success/patient-program-success.component";
import ProgramSuccess from "./programs/programs-success.component";
import ProgramsForm from "./programs/programs.component";
import PSARegistrationSuccessful from "./psa-registration-successful/psa-registration-successful";
import RescheduleBooking from "./reschedule-booking/reschedule-booking";
import RescheduleBookingError from "./reschedule-booking/reschedule-booking-error";
import RescheduleBookingSuccess from "./reschedule-booking/reschedule-booking-success";
import { NotFound, PrivateRoute, Unauthorized } from "./shared/components";
import PrivateEmailRoute from "./shared/components/private-email-route.component";
import { WelcomeScreen } from "./welcome-screen";
import DashboardHome from "./dashboard-home/dashboard-home.component";

function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route path="/auth" element={<Auth />} />
          <Route path="/logout" element={<Logout />} />
          <Route element={<PrivateRoute />}>
            <Route path="/assessment" element={<WelcomeScreen />} />
            <Route path="/" element={<DashboardHome />} />
            <Route path="booking-flow" element={<InsuranceFlow />} />
            <Route path="success" element={<Success />} />
            <Route
              path="patient-program-success"
              element={<PatientProgramSuccess />}
            />
            <Route path="other-condition" element={<OtherConditionEnd />} />
            <Route
              path="PSA-registration-successful"
              element={<PSARegistrationSuccessful />}
            />
          </Route>

          {/* Questions  */}
          <Route path="/program" element={<ProgramsForm />} />
          <Route path="/programs/success" element={<ProgramSuccess />} />

          {/* Email paths */}
          <Route path="/email-auth" element={<EmailAuth />} />
          <Route element={<PrivateEmailRoute />}>
            <Route path="edit-appointment/:id" element={<EditBooking />} />
            <Route
              path="cancel-appointment/:id"
              element={<EditBookingCancel />}
            />
            <Route
              path="cancel-appointment-success"
              element={<CancelBookingSuccess />}
            />
            <Route
              path="reschedule-appointment/:id"
              element={<RescheduleBooking />}
            />
            <Route
              path="reschedule-appointment-success"
              element={<RescheduleBookingSuccess />}
            />
            <Route
              path="reschedule-appointment-error"
              element={<RescheduleBookingError />}
            />
          </Route>

          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
