import React from "react";
import { Link } from "react-router-dom";

const AssessmentCard = ({ title, description, link, isConsent = false }) => {
  return (
    <div className="p-4  bg-white border border-gradient rounded-lg shadow-sm hover:shadow-md transition-shadow flex flex-col justify-between h-full assessment-card">
      <div>
        <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
        <p className="text-gray-500 mt-1">{description}</p>
      </div>
      <Link
        to={link}
        className="text-black font-medium hover:underline flex items-center mt-4">
        {isConsent
          ? "Sign Consent"
          : title.includes("enrolment")
          ? "Start Enrolment"
          : "Start Assessment"}
        <svg
          className="w-4 h-4 ml-1"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M9 5l7 7-7 7"
          />
        </svg>
      </Link>
    </div>
  );
};

export default AssessmentCard;
