import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Navigate } from "react-router-dom";
import { useConsumerStore } from "../../store";
import useServiceConsumer from "../shared/hooks/use-service-consumer.hook";
import websocketLoggerService from "../../services/websocket-logger";

const ConsumerLogin: React.FC = () => {
  const serviceConsumer = useServiceConsumer();

  const [token, setToken] = useConsumerStore((state) => [
      state.token,
      state.setConsumerToken,
    ]),
    setConsumer = useConsumerStore((state) => state.setConsumer),
    { user, isLoading, getIdTokenClaims, loginWithRedirect } = useAuth0();

  React.useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!user) {
      loginWithRedirect({
        redirectUri: window.location.href,
        scope: "openid profile email phone",
        // appState:{
        //   returnTo: "/booking-flow"
        // }
      });
      return;
    }

    const getAccessToken = async () => {
      const token = await getIdTokenClaims();
      setToken(token.__raw);
    };

    getAccessToken();
  }, [user, isLoading, setToken, getIdTokenClaims, loginWithRedirect]);

  if (token) {
    websocketLoggerService.sendMessage({
      authenticationId: user?.sub,
      externalUserId: null,
      eventType: `User signed in`,
      eventSubType: `User signed in via auth0`,
      eventData: `OTP verified, navigating to /booking-flow`,
    });

    return <Navigate to="/" />;
  }

  return null;
};

export default ConsumerLogin;
