import Backdrop from "../shared/components/backdrop.component";

const RescheduleBookingError = () => {
  return (
    <Backdrop>
      <div className="flex flex-col px-20 py-8">
        <div className="text-2xl max-lg:text-xl mt-4 font-extrabold">
          Oops! The Manage Booking link has expired.
        </div>
        <div className="mt-4">
          Please make changes to your appointment using the Abc Health™ Health
          App or contact your coach directly. Thank you!
        </div>
      </div>
    </Backdrop>
  );
};

export default RescheduleBookingError;
