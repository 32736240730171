import CalendarIcon from "./icons/calendar-icon";
import ChatIcon from "./icons/chat-icon";
import HomeIcon from "./icons/home-icon";
import SearchIcon from "./icons/search-icon";

export const storageTypes = {
  local: localStorage,
  session: sessionStorage,
};

export enum EventType {
  OneOnOneEvent = "ONE_ON_ONE_EVENT",
}

export enum ScopeType {
  Consumer = "Consumer",
  Probider = "Provider",
}

export const keys = {
  auth: "authInfo",
  storage: storageTypes.session,
};

export const urls = {
  baseUrl: process.env.REACT_APP_API_URL,
  login: `login`,
  logger: `logger`,
};

export const CONSUMER_MISSING_PARAM_ERROR =
  "Missing required paramter to continue with appointment booking. Please contact system administrator.";

const DEV = {
  orgid: 67,
  "care-provider": "59fb827a-926d-4495-ae14-af494aab54fb",
  "program-name": "prog-abchealth-pre-enrolment",
  "full-program-name": "prog-abchealth-diabetes-management-programme",
  "encounter-template-name": "etmp-introductory-coaching-session",
  card: {
    "cdt-name": "cdt-cr-demographics",
    "cdt-field": "cdtf-chascard",
  },
  ethnicity: {
    "cdt-name": "cdt-cr-demographics",
    "cdt-field": "cdtf-race",
  },
  employeeid: {
    "cdt-name": "cdt-cr-demographics",
    "cdt-field": "cdtf-empid",
  },
  "long-term-condition": {
    "cdt-name": "cdt-cr-diagnosis",
    "cdt-field": {
      "Type 2 Diabetes":
        "Type 2 Diabetes Mellitus--0074891f-68a9-4ac0-9f41-d5497b42be64",
      "High Blood pressure":
        "Essential Hypertension--c97a700c-cbf5-4690-854a-8fa8409d5eee",
      Cholesterol:
        "Hyperlipidaemia, Unspecified--b98a0e6d-c061-4171-897b-540f41efc11e",
    },
  },
  department: "department",
  referralcodes: {
    "PSA-001": {
      name: "PSA-001",
      region: "rgt-psa",
      territory: "001",
      excelsheetid: "1WQvV0OiO9mbTyXvsWi2kZ2QcBxVQcv_vfCZUMVANr-s",
      locationfilter: "rgt-psa",
      isExpired: false,
      consentTermsLink: "https://www.abchealth.sg/clinic-partners-consent",
    },
    NEMG: {
      name: "NEMG",
      region: "rgt-central-singapore",
      territory: null,
      excelsheetid: "1uo41Rtj6iHzmJ13hx06OWeR1CMcCtKvVs_1HORkQEKU",
      locationfilter: "rgt-central-singapore",
      isExpired: false,
      consentTermsLink: "https://www.abchealth.sg/clinic-partners-consent",
    },
  },
};

// update values to correct prod values

const PROD = {
  orgid: 6,
  "care-provider": "59fb827a-926d-4495-ae14-af494aab54fb",
  "program-name": "prog-abchealth-pre-enrolment",
  "full-program-name": "prog-abchealth-diabetes-management-programme",
  "encounter-template-name": "etmp-introductory-coaching-session",
  card: {
    "cdt-name": "cdt-cr-demographics",
    "cdt-field": "cdtf-chascard",
  },
  ethnicity: {
    "cdt-name": "cdt-cr-demographics",
    "cdt-field": "cdtf-race",
  },
  employeeid: {
    "cdt-name": "cdt-cr-demographics",
    "cdt-field": "cdtf-empid",
  },
  "long-term-condition": {
    "cdt-name": "cdt-cr-diagnosis",
    "cdt-field": {
      "Type 2 Diabetes":
        "Type 2 Diabetes Mellitus--ca47572a-b3e6-4ceb-8f3f-4aad9c121a2e",
      "High Blood pressure":
        "Essential Hypertension--082d8b20-d126-4947-ac76-4e4f761dd9b1",
      Cholesterol:
        "Hyperlipidaemia, Unspecified--57bfc232-6d7a-45f5-bb0f-a6aded14222c",
    },
  },
  department: "department",
  referralcodes: {
    "PSA-001": {
      name: "PSA-001",
      region: "rgt-psa",
      territory: "001",
      excelsheetid: "1bKZYWb6gL4GcXviaed3Vs8l7tF2ddYGUUtL5ZNyZH6Q",
      locationfilter: "rgt-psa",
      isExpired: true,
      consentTermsLink: "https://www.abchealth.sg/clinic-partners-consent",
    },
    NEMG: {
      name: "NEMG",
      region: "rgt-central-singapore",
      territory: null,
      excelsheetid: "10W_CUyipgkIdBvqO5ajFfcXhrnRXEhzO0XxeB2KiwDM",
      locationfilter: "rgt-central-singapore",
      isExpired: false,
      consentTermsLink: "https://www.abchealth.sg/clinic-partners-consent",
    },
  },
};

export const cdtMapping = process.env.REACT_APP_IS_PROD === "true" ? PROD : DEV;

const appConstants = {
  keys,
  urls,
  cdtMapping,
};

export const sidebarLinks = [
  {
    to: "/home",
    messageId: "sidebar.consent_management",
    activePath: "/home",
    Icon: HomeIcon,
    roles: [],
    name: "Home",
  },
  {
    to: "/scheduler/book-appointment/workflow/new/new/new",
    messageId: "sidebar.book_appointment",
    activePath: "/scheduler/book-appointment",
    Icon: SearchIcon,
    roles: [],
    name: "Explore",
  },
  {
    to: "/scheduler/manage-schedule/all-appointments",
    messageId: "sidebar.manage_appointment",
    activePath: "/scheduler/manage-schedule",
    Icon: ChatIcon,
    roles: [],
    name: "Chats",
  },
  {
    to: "/manage-patients/workflow/new/new/patient/lookup",
    messageId: "sidebar.manage_patients",
    activePath: "/manage-patients",
    Icon: CalendarIcon,
    roles: [],
    name: "Appointments",
  },

  // {
  //   to: "/config/release-note",
  //   messageId: "Release Notes",
  //   Icon: ,
  // },
  // {
  //   to: "/config/localization/projects",
  //   messageId: "Localization",
  //   Icon: ,
  // },
  // {
  //   to: "/config/usage-analytics/projects",
  //   messageId: "Usage Analytics",
  //   Icon: ,
  // },
  // {
  //   to: "/config/notifications/projects",
  //   messageId: "Notifications",
  //   Icon: ,
  // },
  // {
  //   to: "/config/configurations",
  //   messageId: "Configurations",
  //   Icon: ,
  // },
  // {
  //   to:"/config/members",
  //   messageId: "Members",
  //   Icon: ,
  // },
  // {
  //   to:"/config/audit-logs",
  //   messageId: "Audit logs",
  //   Icon: ,
  // },
  // {
  //   to:"/scheduler/events",
  //   messageId: "Events",
  //   Icon: ,
  // },
  // {
  //   to:"/scheduler/appointments",
  //   messageId: "Appointments",
  //   Icon: ,
  // },
  // {
  //   to:"/scheduler/availability",
  //   messageId: "Availability",
  //   Icon: ,
  // },
  // {
  //   to:"/scheduler/integrations",
  //   messageId: "Integrations",
  //   Icon: ,
  // },
  // {
  //   to:"/config/user-management/all",
  //   messageId: "User Management",
  //   Icon: ,
  // },
  // {
  //   to:"/scheduler/admin/event-templates",
  //   messageId: "Event Templates",
  //   Icon: ,
  // },
  // {
  //   to:"/scheduler/admin/users",
  //   messageId: "Users",
  //   Icon: ,
  // },
  // {
  //   to:"/scheduler/admin/users",
  //   messageId: "Users",
  //   Icon: ,
  // },
  // {
  //   to:"/scheduler/admin/dashboard",
  //   messageId: "Dashboard",
  //   Icon: ,
  // },
];

export default appConstants;
