import { useEffect } from "react";
import { useFormStore } from "../../store/form";
import Step from "./components/step.component";
import { useNavigate } from "react-router-dom";
import heroImg from "../../assets/images/hero-img.png";
interface ISteps {
  step: number;
  setStep: (step: number) => void;
}

const Steps: React.FC<ISteps> = ({ step, setStep }) => {
  const navigate = useNavigate();

  const { referralCode } = useFormStore();

  // if (referralCode === null) {
  //   navigate("/");
  // }

  const steps = referralCode?.includes("PSA-001")
    ? [
        {
          title: "1. Provide Personal Information",
        },
      ]
    : [
        {
          title: "1. Provide Personal Information",
        },
        {
          title: "2. Schedule Session",
        },
      ];

  return (
    <div
      style={{
        // backgroundImage: "url('hero-img-black.png')",
        backgroundImage: "url('hero-img-white.png')",
        // backgroundImage: "url('hero-img-blue.png')",
      }}
      className="
   bg-bottom bg-no-repeat bg- backdrop-blur-sm bg-[#F6F6FB]
    flex-1 pr-32  pl-32 pt-8  flex flex-col gap-6 max-lg:flex-col-reverse max-lg:w-full max-lg:overflow-x-auto max-lg:px-5 max-lg:bg-[#F6F6FB] max-lg:pt-5">
      {/* <img
        src={heroImg}
        alt=""
        className="absolute w-full h-96 z-[-1] left-0 right-0 top-0 filter blur-sm max-lg:hidden "
      /> */}

      <div className="max-lg:flex ">
        {steps.map((item, index) => (
          <Step
            setStep={setStep}
            key={index}
            completed={step > index}
            step={index + 1}
            currentStep={step}
            active={step === index}
            title={item.title}
          />
        ))}
      </div>
    </div>
  );
};

export default Steps;
