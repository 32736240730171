import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";

const SidebarLink = ({
  to,
  messageId,
  Icon,
  activePath,
  handleNavLinkClick,
  name,
}) => {
  const location = useLocation();

  const isActive = location.pathname.includes(activePath);

  return (
    <button
      onClick={() => handleNavLinkClick(to)}
      className={`has-tooltip flex w-full transform items-center space-x-3 rounded-lg bg-opacity-40 py-[6px] pl-4 pr-2 text-gray-500 transition-colors duration-200 hover:bg-gray-100 hover:text-gray-600 ${
        isActive && "bg-nav-active-gradient text-primary-blue"
      }`}>
      <Icon isActive={isActive} />

      <span
        className={`${"hidden"} left-8 rounded bg-white p-2 text-sm text-black shadow`}>
        <FormattedMessage id={messageId} defaultMessage={name} />
      </span>

      <span className={`${isActive && "text-primary-blue"} capitalize`}>
        <FormattedMessage id={messageId} defaultMessage={name} />
      </span>
    </button>
  );
};

export default SidebarLink;
