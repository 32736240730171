const ChatInterface = () => {
  return (
    <div className="flex h-screen">
      <div className=" bg-white p-6">
        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-4">Chats</h2>
          <p className="text-gray-600">Chat with doctors and specialists</p>
          <div className="flex space-x-4 mt-4 overflow-clip w-full">
            <div className="w-1/2 px-4 py-8 pb-4 bg-gray-50 rounded-lg text-center shadow">
              <img
                src="https://images.unsplash.com/photo-1584467735815-f778f274e296?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Doctor"
                className="rounded-md w-24 h-24 mx-auto object-cover"
              />
              <p className="mt-2 font-semibold">Dr. Jack </p>
              <p className="text-gray-500 text-sm">PSYCHIATRIST</p>
              <button className="mt-4 w-full bg-primary-700/20 text-primary-700 px-4 py-2 rounded-md">
                Chat Now
              </button>
            </div>
            <div className="w-1/2 px-4 py-8 pb-4 bg-gray-50 rounded-lg text-center shadow">
              <img
                src="https://plus.unsplash.com/premium_photo-1661764878654-3d0fc2eefcca?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Doctor"
                className="rounded-md w-24 h-24 mx-auto object-cover"
              />
              <p className="mt-2 font-semibold">Dr. Michael </p>
              <p className="text-gray-500 text-sm">CARDIOLOGIST</p>
              <button className="mt-4 w-full bg-primary-700/20 text-primary-700 px-4 py-2 rounded-md">
                Chat Now
              </button>
            </div>
            {/* <div className="w-30 p-4 bg-gray-50 rounded-lg text-center shadow">
              <img
                src="https://via.placeholder.com/150"
                alt="Consultant"
                className="rounded-full w-24 h-24 mx-auto"
              />
              <p className="mt-2 font-semibold">Consult a Specialist</p>
              <button className="mt-4 bg-indigo-500 text-white px-4 py-2 rounded-full">
                Chat Now
              </button>
            </div> */}
          </div>
          <div className="flex justify-center mt-4">
            <div className="w-2 h-2 bg-indigo-500 rounded-full mx-1"></div>
            <div className="w-2 h-2 bg-gray-300 rounded-full mx-1"></div>
            <div className="w-2 h-2 bg-gray-300 rounded-full mx-1"></div>
            <div className="w-2 h-2 bg-gray-300 rounded-full mx-1"></div>
          </div>
        </div>
        <div>
          <h3 className="text-lg font-semibold">Ongoing conversations</h3>
          <div className="mt-4 space-y-4">
            <div className="p-4 bg-white border border-primary-700/40 rounded-lg shadow">
              <div className="flex items-center space-x-4">
                <img
                  src="https://plus.unsplash.com/premium_photo-1661766718556-13c2efac1388?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8ZG9jdG9yfGVufDB8fDB8fHww"
                  alt="Doctor"
                  className="w-10 h-10 rounded-full object-cover"
                />
                <div>
                  <p className="font-semibold">To Dr. Adam Jones</p>
                  <p className="text-gray-500 text-sm">2 days ago</p>
                </div>
              </div>
              <p className="mt-2 text-gray-700">
                Hello Doctor, I’m having headaches 2-3 times a week. Is this
                normal or ....
              </p>
            </div>
            <div className="p-4 bg-white border border-primary-700/40 rounded-lg shadow">
              <div className="flex items-center space-x-4">
                <img
                  src="https://plus.unsplash.com/premium_photo-1681996484614-6afde0d53071?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGRvY3RvcnxlbnwwfHwwfHx8MA%3D%3D"
                  alt="Doctor"
                  className="w-10 h-10 rounded-full object-cover"
                />
                <div>
                  <p className="font-semibold">To Dr. Mehta</p>
                  <p className="text-gray-500 text-sm">2 days ago</p>
                </div>
              </div>
              <p className="mt-2 text-gray-700">
                Are you experiencing any other symptoms? Have you had any recent
                injuries or accidents?
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatInterface;
