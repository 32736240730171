import React from "react";

const ChatIcon = ({ props }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 8.62502C15.7526 9.61492 15.5213 10.5914 15.075 11.475C13.9957 13.6345 11.7892 14.9991 9.375 15C8.3851 15.0026 7.40859 14.7713 6.525 14.325L2.25 15.75L3.675 11.475C3.2287 10.5914 2.99742 9.61492 3 8.62502C3.00093 6.21084 4.3655 4.0043 6.525 2.92502C7.40859 2.47872 8.3851 2.24744 9.375 2.25002H9.75C12.9869 2.4286 15.5714 5.01316 15.75 8.25002V8.62502V8.62502Z"
        stroke="#768089"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChatIcon;
