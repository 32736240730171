import React from "react";

const SearchIcon = ({ props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
      {...props}>
      <path
        fill="#768089"
        fillRule="evenodd"
        d="M11.289 11.29a.938.938 0 0 1 1.326 0l3.61 3.61a.937.937 0 1 1-1.326 1.327l-3.61-3.61a.94.94 0 0 1 0-1.326Z"
        clipRule="evenodd"
      />
      <path
        fill="#768089"
        fillRule="evenodd"
        stroke="#768089"
        d="M7.594 12.75a5.156 5.156 0 1 0 0-10.31 5.156 5.156 0 0 0 0 10.31Zm6.094-5.156a6.094 6.094 0 1 1-12.188 0 6.094 6.094 0 0 1 12.188 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SearchIcon;
