import { useLocation, useNavigate } from "react-router-dom";
import Backdrop from "../shared/components/backdrop.component";
const PSARegistrationSuccessful: React.FC = () => {
  const navigate = useNavigate();
  const handleDone = () => {
    navigate("/logout");
  };

  const { state } = useLocation();

  return (
    <Backdrop>
      <div className="flex flex-col items-center p-10 px-16 gap-4 max-lg:p-2 max-lg:w-full">
        <div className="text-8xl max-lg:text-4xl">🎉</div>
        {state?.isAlreadyRegistered ? (
          <div className="max-w-xl text-center max-lg:w-full">
            Hi, you are already registered in the ABC Health Programme.
            <p>
              If you need to make any changes or have any questions, please feel
              free to reach out to the ABC Health team at{" "}
              <a
                className="text-primary-800 text-over"
                href="mailto:abc-healthadmin@sheareshealthcare.com.sg">
                abc-healthadmin@sheareshealthcare.com.sg
              </a>
            </p>
            Thank you!
          </div>
        ) : (
          <>
            <h1 className="text-xl font-bold text-center">
              Registration Successful
            </h1>
            <span className="max-w-lg text-center max-lg:w-full">
              Thank you for your interest in signing up for the Abc Health™
              Introductory Session! Next, we will reach out to you to schedule
              the session
            </span>

            <div className="text-center max-w-lg max-lg:w-full max-lg:text-md">
              If you want to reach out before the session, drop us an email at{" "}
              <a
                className="text-primary-800 text-over"
                href="mailto:abc-healthadmin@sheareshealthcare.com.sg">
                abc-healthadmin@sheareshealthcare.com.sg
              </a>
            </div>
          </>
        )}

        <button
          onClick={handleDone}
          className="w-max bg-primary-800 px-12 py-4 mt-4 text-white rounded font-bold">
          Done
        </button>
      </div>
    </Backdrop>
  );
};

export default PSARegistrationSuccessful;
