import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IAppointment } from "../../interfaces";
import { loggerService, networkService } from "../../services";
import websocketLoggerService from "../../services/websocket-logger";
import { useConsumerStore } from "../../store";
import Backdrop from "../shared/components/backdrop.component";

const EditBooking: React.FC = () => {
  const navigate = useNavigate(),
    consumer = useConsumerStore((state) => state.consumer);
  const { user } = useAuth0();
  const authenticatedUser = {
    externalUserId: consumer.id,
    authenticationId: user?.sub,
  };

  const [token, setToken] = useState<string>("");
  const [isBooked, setIsBooked] = useState<boolean>(false);
  useEffect(() => {
    const token = window.location.pathname.split("/")[2];
    setToken(token);
  }, []);

  const { id: appointmentToken } = useParams();
  const getAppointmentDetails = () => {
    // const appointmentToken: string = window?.location?.pathname?.split("/")[2];

    networkService
      .get<IAppointment>(
        `/api/scheduling/patients/${consumer.id}/appointments/${appointmentToken}`
      )
      .then((res: IAppointment) => {
        websocketLoggerService.sendMessage({
          ...authenticatedUser,
          eventType: `API request: getAddresses: GET /api/scheduling/patients/${consumer.id}/appointments/${appointmentToken}`,
          eventSubType: `Get appointment details by token for reschedule appointmnet`,
          eventData: "fetched user's appointment successfully",
        });

        if (res?.status !== "BOOKED" || new Date(res?.date) < new Date()) {
          navigate("/reschedule-appointment-error", { replace: true });
        } else {
          setIsBooked(true);
        }
      })
      .catch((err) => {
        loggerService.error(err);
        navigate("/reschedule-appointment-error", { replace: true });
        websocketLoggerService.sendMessage({
          ...authenticatedUser,
          eventType: `API request: getAddresses: GET /api/scheduling/patients/${consumer.id}/appointments/${appointmentToken}`,
          eventSubType: `Get appointment details by token`,
          eventData: `could not fetch user's appointment, Error: ${JSON.stringify(
            err
          )}`,
        });
      });
  };

  React.useEffect(() => {
    getAppointmentDetails();
  }, []);

  return (
    <Backdrop>
      {isBooked && (
        <div className="p-10 px-20 max-w-3xl max-lg:w-full max-lg:p-2">
          <h1 className="text-3xl font-bold text-[#1A1A1A] mb-5 max-lg:text-2xl">
            Edit my booking
          </h1>
          <p>
            At Abc Health™, we're here to help you better manage your condition.
            Our health programme can provide valuable insights and tools to help
            you achieve your health goals.
          </p>
          <p className="mt-8">
            If the timing of your appointment for the introductory session no
            longer works for you, do consider rescheduling. If you can't find a
            suitable slot, please don't hesitate to contact us at
            abc-healthadmin@sheareshealthcare.com.sg and we'll do our best to
            arrange a new appointment time that suits you better.
          </p>
          <div className="flex gap-4 mt-16">
            <Link
              to={`/reschedule-appointment/${token}`}
              className="flex justify-center items-center cursor-pointer bg-primary-500 w-40 py-3 text-white rounded font-bold">
              Reschedule
            </Link>
            <Link
              to={`/cancel-appointment/${token}`}
              className="flex justify-center items-center cursor-pointer bg-abchealth-white border border-abchealth-gray-600 w-40 py-3 text-abchealth-gray-900 rounded font-bold">
              Cancel Booking
            </Link>
          </div>
        </div>
      )}
    </Backdrop>
  );
};

export default EditBooking;
