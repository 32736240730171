import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuthenticated } from "../hooks";
import { useConsumerStore } from "../../../store";

const PrivateRoute: React.FC = () => {
  const location = useLocation();
  const isAuthenticated = useAuthenticated();

  const [token, setToken] = useConsumerStore((state) => [
    state.token,
    state.setConsumerToken,
  ]);

  // const subDomainData = useSubDomainStore((state) => state.subDomainData);

  console.log("location", location);

  if (!token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }

  //

  return <Outlet />;
};

export default PrivateRoute;
