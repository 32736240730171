const HomeIcon = ({ props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
      {...props}>
      <path
        stroke="url(#a)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M2.25 6.75 9 1.5l6.75 5.25V15a1.5 1.5 0 0 1-1.5 1.5H3.75a1.5 1.5 0 0 1-1.5-1.5V6.75Z"
        clipRule="evenodd"
      />
      <defs>
        <linearGradient
          id="a"
          x1={-7.659}
          x2={20.296}
          y1={-6.336}
          y2={1.251}
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFE600" />
          <stop offset={0.46} stopColor="#09C153" />
          <stop offset={0.667} stopColor="#1C70D2" />
          <stop offset={0.981} stopColor="#4B1CD2" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default HomeIcon;
