import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useConsumerStore } from "../../store";
import Backdrop from "../shared/components/backdrop.component";
import { cdtMapping } from "../shared/config";

const RescheduleBookingSuccess = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const { user } = useAuth0();
  const consumer = useConsumerStore((state) => state.consumer);

  function findExcelSheetIdByRegion(regionName) {
    for (const referralCode in cdtMapping.referralcodes) {
      if (cdtMapping.referralcodes[referralCode]?.region === regionName) {
        return cdtMapping.referralcodes[referralCode]?.excelsheetid;
      }
    }
    return null; // Return null if no matching referral code is found
  }

  React.useEffect(() => {
    if (!state || !user) return;

    const valuesToLog = state.valuesToLog;

    const regionName = consumer.regionName;
    const organizationId = cdtMapping.orgid;

    const excelSheetId = findExcelSheetIdByRegion(regionName);

    localStorage.clear();
  }, [navigate, state]);

  return (
    <Backdrop>
      <div className="flex flex-col items-center p-10 px-20 gap-4 max-lg:p-2 max-lg:w-full">
        <div className="text-8xl max-lg:text-4xl">🎉</div>
        <h1 className="text-xl font-bold text-center">
          Your appointment has been rescheduled
        </h1>
        <span className="max-w-lg text-center max-lg:w-full">
          Thank you for signing up for the Abc Health™ Introductory Session! We
          look forward to meeting you soon.
        </span>
        <div className="border border-primary-800 bg-white w-80 rounded-md ">
          <div className="flex items-center p-4 gap-6  rounded-t-md">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_95_2337)">
                <path
                  d="M6.382 6.13109C7.97575 4.85415 9.95781 4.15983 12 4.16309C14.125 4.16309 16.078 4.89909 17.618 6.13109L19.071 4.67809L20.485 6.09209L19.032 7.54509C20.0897 8.86923 20.7521 10.4651 20.9428 12.1491C21.1336 13.833 20.845 15.5366 20.1103 17.0638C19.3756 18.591 18.2246 19.8797 16.7898 20.7817C15.355 21.6836 13.6947 22.1621 12 22.1621C10.3053 22.1621 8.64498 21.6836 7.2102 20.7817C5.77541 19.8797 4.62443 18.591 3.88973 17.0638C3.15502 15.5366 2.86644 13.833 3.0572 12.1491C3.24796 10.4651 3.9103 8.86923 4.96801 7.54509L3.51501 6.09309L4.92901 4.67909L6.382 6.13209V6.13109ZM12 20.1631C12.9193 20.1631 13.8295 19.982 14.6788 19.6302C15.5281 19.2785 16.2997 18.7628 16.9498 18.1128C17.5998 17.4628 18.1154 16.6911 18.4672 15.8419C18.8189 14.9926 19 14.0823 19 13.1631C19 12.2438 18.8189 11.3336 18.4672 10.4843C18.1154 9.63502 17.5998 8.86335 16.9498 8.21334C16.2997 7.56333 15.5281 7.04771 14.6788 6.69593C13.8295 6.34415 12.9193 6.16309 12 6.16309C10.1435 6.16309 8.36301 6.90058 7.05026 8.21334C5.7375 9.52609 5.00001 11.3066 5.00001 13.1631C5.00001 15.0196 5.7375 16.8001 7.05026 18.1128C8.36301 19.4256 10.1435 20.1631 12 20.1631V20.1631ZM13 12.1631H16L11 18.6631V14.1631H8.00001L13 7.65809V12.1631ZM8.00001 1.16309H16V3.16309H8.00001V1.16309Z"
                  fill="#2A27D5"
                />
              </g>
              <defs>
                <clipPath id="clip0_95_2337">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0 0.163086)"
                  />
                </clipPath>
              </defs>
            </svg>
            <div>
              {" "}
              {moment(state.startDateTime).format("hh:mm A")} -
              {moment(state.endDateTime).format("hh:mm A")}
            </div>
          </div>
          <div className="flex items-center p-4 gap-6 border-t border-b border-b-primary-800 border-t-primary-800">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_95_2338)">
                <path
                  d="M9 1.16309V3.16309H15V1.16309H17V3.16309H21C21.2652 3.16309 21.5196 3.26844 21.7071 3.45598C21.8946 3.64352 22 3.89787 22 4.16309V20.1631C22 20.4283 21.8946 20.6827 21.7071 20.8702C21.5196 21.0577 21.2652 21.1631 21 21.1631H3C2.73478 21.1631 2.48043 21.0577 2.29289 20.8702C2.10536 20.6827 2 20.4283 2 20.1631V4.16309C2 3.89787 2.10536 3.64352 2.29289 3.45598C2.48043 3.26844 2.73478 3.16309 3 3.16309H7V1.16309H9ZM20 10.1631H4V19.1631H20V10.1631ZM15.036 11.2991L16.45 12.7131L11.5 17.6631L7.964 14.1271L9.38 12.7131L11.501 14.8351L15.037 11.2991H15.036ZM7 5.16309H4V8.16309H20V5.16309H17V6.16309H15V5.16309H9V6.16309H7V5.16309Z"
                  fill="#2A27D5"
                />
              </g>
              <defs>
                <clipPath id="clip0_95_2338">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0 0.163086)"
                  />
                </clipPath>
              </defs>
            </svg>

            <div>{moment(state.date).format("dddd DD MMMM YYYY")}</div>
          </div>
          <div className="flex items-center p-4 gap-6 rounded-b-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="36"
              height="36">
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4V1h2v2h6V1h2v2zm3 8H4v8h16v-8zm-5-6H9v2H7V5H4v4h16V5h-3v2h-2V5zm-9 8h2v2H6v-2zm5 0h2v2h-2v-2zm5 0h2v2h-2v-2z"
                fill="rgba(42,39,213,1)"
              />
            </svg>

            <div className="flex flex-col">
              <span>
                {state?.location.title}: {state.location.line1},{" "}
                {state.location.line2}, {state.location.country}{" "}
                {state.location.zipCode}
              </span>
            </div>
          </div>
        </div>
        <div className="text-center max-w-lg max-lg:w-full max-lg:text-md">
          We will send a confirmation either through email or text messages.{" "}
          <p className="font-bold">
            if you don't find the confirmation email in your inbox, please check
            your junk/spam mail folder.
          </p>
          <p className="mt-4">
            If you want to reach out before the session, drop us an email at{" "}
            <span className="text-primary-800 text-over">
              abc-healthadmin@sheareshealthcare.com.sg
            </span>
          </p>
        </div>
      </div>
    </Backdrop>
  );
};

export default RescheduleBookingSuccess;
