import React from "react";

const ArticleCard = ({ image, category, title }) => {
  return (
    <div className="flex items-start  bg-white  rounded-lg  space-x-4">
      <img
        src={image}
        alt={category}
        className="w-20 h-20 rounded-md object-cover"
      />
      <div className="flex-1">
        <h3 className="text-sm font-semibold text-gray-800 leading-tight">
          {title}
        </h3>
        <span className="mt-2 inline-block px-2 py-1 text-xs text-blue-700 bg-primary-700/10 rounded text-primary-500">
          {category}
        </span>
      </div>
      <button className="text-blue-600 text-grey hover:text-blue-800">
        <svg
          className="w-5 h-5"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 21l-7-5-7 5V5a2 2 0 012-2h10a2 2 0 012 2z"
          />
        </svg>
      </button>
    </div>
  );
};

export default ArticleCard;
