import { Link } from "react-router-dom";
import Sidebar from "./components/sidebar.component";
import HomeContent from "./components/home-content";
import ChatInterface from "./components/chat-interface.component";
import { useConsumerStore } from "../../store";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import websocketLoggerService from "../../services/websocket-logger";
import { IPatientInfo } from "../../interfaces";
import { networkService } from "../../services";

const DashboardHome = () => {
  const [token, setToken] = useConsumerStore((state) => [
    state.token,
    state.setConsumerToken,
  ]);

  const { user } = useAuth0();
  const [setConsumerState, consumer, setConsumer] = useConsumerStore(
    (state) => [state.setConsumerState, state.consumer, state.setConsumer]
  );

  useEffect(() => {
    if (!user) return;

    // const checkPatientProgram = async (userID) => {
    //   const userProgram = await networkService.get(
    //     `/api/patients/${userID as string}/programs/${
    //       cdtMapping["full-program-name"]
    //     }`
    //   );
    //   console.log(userProgram);

    //   if (userProgram) {
    //     navigate("/patient-program-success");
    //   }
    // };

    const getServiceConsumerByEmail = async (patientEmail: string) => {
      if (!patientEmail) return;

      const patientEmailEncoded = patientEmail.replaceAll("+", "%2B");

      try {
        const response: IPatientInfo = await networkService.get(
          `/api/patients?email=${patientEmailEncoded}`
        );

        // console.log(response);
        websocketLoggerService.sendMessage({
          externalUserId: response.id,
          authenticationId: user?.sub,
          eventType: `API request: Get patient by phone number, PUT /api/patients/phone`,
          eventSubType: `check if patient exists in EHR, referralCode: `,
          eventData: "patient exists in EHR",
        });

        return response;
      } catch (error) {
        console.log(error);
        console.log(error);
        websocketLoggerService.sendMessage({
          externalUserId: null,
          authenticationId: user?.sub,
          eventType: `API request: Get patient by phone number, PUT /api/patients/phone`,
          eventSubType: `check if patient exists in EHR, referralCode: `,
          eventData: "patient does not exist in EHR",
        });
        return {
          id: null,
          firstName: null,
          lastName: null,
          middleName: null,
          gender: null,
          birthDate: null,
          addressLine1: null,
          addressLine2: null,
          zipCode: null,
          mobileNumber: user.phone_number,
          email: null,
          regionName: null,
          territory: null,
          dependants: [],
        };
      }
    };

    const returnPatient = async () => {
      const patient: any = await getServiceConsumerByEmail(user?.email);

      // get timezone from browser
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const patientInfoWithTimezone = {
        ...patient,
        timeZone: timezone,
      };

      setConsumer(patientInfoWithTimezone);
      // await checkPatientProgram(patientInfoWithTimezone.id);

      return patient;
    };
    returnPatient();
  }, [setConsumer, user]);

  return (
    <main className="flex">
      <div className="h-screen w-60 px-4 border-r border-grey">
        <Sidebar />
      </div>
      <div className="flex-[2]">
        <HomeContent />
      </div>
      <div className="h-screen w-1/4 border-l border-grey">
        <ChatInterface />
      </div>
    </main>
  );
};

export default DashboardHome;
