import React from "react";

const CalendarIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.20312 2.25C5.31501 2.25 5.42232 2.29445 5.50144 2.37356C5.58055 2.45268 5.625 2.55999 5.625 2.67188V3.09375H12.375V2.67188C12.375 2.55999 12.4194 2.45268 12.4986 2.37356C12.5777 2.29445 12.685 2.25 12.7969 2.25C12.9088 2.25 13.0161 2.29445 13.0952 2.37356C13.1743 2.45268 13.2188 2.55999 13.2188 2.67188V3.09375H14.0625C14.5101 3.09375 14.9393 3.27154 15.2557 3.58801C15.5722 3.90447 15.75 4.3337 15.75 4.78125V14.0625C15.75 14.5101 15.5722 14.9393 15.2557 15.2557C14.9393 15.5722 14.5101 15.75 14.0625 15.75H3.9375C3.48995 15.75 3.06072 15.5722 2.74426 15.2557C2.42779 14.9393 2.25 14.5101 2.25 14.0625V4.78125C2.25 4.3337 2.42779 3.90447 2.74426 3.58801C3.06072 3.27154 3.48995 3.09375 3.9375 3.09375H4.78125V2.67188C4.78125 2.55999 4.8257 2.45268 4.90481 2.37356C4.98393 2.29445 5.09124 2.25 5.20312 2.25V2.25ZM3.9375 3.9375C3.71372 3.9375 3.49911 4.02639 3.34088 4.18463C3.18264 4.34286 3.09375 4.55747 3.09375 4.78125V5.625H14.9062V4.78125C14.9062 4.55747 14.8174 4.34286 14.6591 4.18463C14.5009 4.02639 14.2863 3.9375 14.0625 3.9375H3.9375ZM14.9062 6.46875H3.09375V14.0625C3.09375 14.2863 3.18264 14.5009 3.34088 14.6591C3.49911 14.8174 3.71372 14.9062 3.9375 14.9062H14.0625C14.2863 14.9062 14.5009 14.8174 14.6591 14.6591C14.8174 14.5009 14.9062 14.2863 14.9062 14.0625V6.46875Z"
        fill="#768089"
        stroke="#768089"
        strokeWidth="0.6"
      />
    </svg>
  );
};

export default CalendarIcon;
