import React from "react";

const DoctorCard = ({ image, specialty, name, link }) => {
  return (
    <div className="flex items-center p-3 flex-1 bg-white border border-light-primary rounded-lg shadow-sm hover:shadow-md transition-shadow">
      <img
        src={image}
        alt={name}
        className="w-16 h-16 rounded-md object-cover"
      />
      <div className="ml-4">
        <h3 className="text-lg font-semibold text-gray-800">{specialty}</h3>
        <p className="text-gray-500 text-xs">{name}</p>
        <a
          href={link}
          className="text-primary-500 font-medium hover:underline flex items-center mt-2">
          Book Now
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </a>
      </div>
    </div>
  );
};

export default DoctorCard;
