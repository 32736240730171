import { useAuth0 } from "@auth0/auth0-react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { IServiceConsumer } from "../../interfaces";
import { networkService } from "../../services";
import { useConsumerStore } from "../../store";
import { useFormStore } from "../../store/form";
import FieldError from "../shared/components/field-error.component";

import websocketLoggerService from "../../services/websocket-logger";
import { cdtMapping } from "../shared/config";

const phoneRegExp = /^(\+\d{1,3})?\d{10}$/;

interface PatientInfoFormProps {
  setStep: (step: number) => void;
  setUserInfoLog: (userInfoLog: any) => void;
  isRegistered: (isRegistered: boolean) => void;
}

interface IPatientInfo {
  id: string;
  firstName: string;
  lastName: string;
  middleName: string;
  gender: string;
  birthDate: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  mobileNumber: string;
  email: string;
}

const PatientInfoForm: React.FC<PatientInfoFormProps> = ({
  setStep,
  setUserInfoLog,
  isRegistered,
}) => {
  const minDate = moment(new Date("1900/01/01")).format("YYYY-MM-DD");
  const maxDate = moment(new Date()).format("YYYY-MM-DD");
  const { user } = useAuth0();

  const { referralCode } = useFormStore();
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [isMobileUniqueError, setIsMobileUniqueError] =
    useState<boolean>(false);
  const [isEmailUniqueError, setIsEmailUniqueError] = useState<boolean>(false);
  const [setConsumerState, consumer, setConsumer] = useConsumerStore(
    (state) => [state.setConsumerState, state.consumer, state.setConsumer]
  );
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!referralCode) {
  //     navigate("/");
  //   }
  // }, []);

  const [responses, setResponses] = useState({
    Symptoms: [],
  });

  const handleResponseChange = (question: string, value: string | string[]) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [question]: value,
    }));
  };

  const isCorporateUser = referralCode?.includes("-");

  const schema = Yup.object().shape({
    CorporateUser: Yup.boolean(),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),

    employeeId: Yup.string().when("CorporateUser", {
      is: true,
      then: Yup.string().required("Employee ID is required"),
    }),
    department: Yup.string().when("CorporateUser", {
      is: true,
      then: Yup.string().required("Department is required"),
    }),
    dateOfBirth: Yup.date()
      .min(new Date("1900/01/01"), "Minimum date limit is 1900/01/01")
      .max(new Date(), "Please enter a valid date.")
      .required("Date of birth is required."),
    gender: Yup.string().required("Gender is required."),
    mobileNumber: Yup.string()
      .required("Mobile Number is required.")
      .matches(phoneRegExp, "Please enter a valid phone number"),
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  useEffect(() => {
    if (!user) return;

    // const checkPatientProgram = async (userID) => {
    //   const userProgram = await networkService.get(
    //     `/api/patients/${userID as string}/programs/${
    //       cdtMapping["full-program-name"]
    //     }`
    //   );
    //   console.log(userProgram);

    //   if (userProgram) {
    //     navigate("/patient-program-success");
    //   }
    // };

    const returnPatient = async () => {
      const patient: any = await getServiceConsumerByEmail(user?.email);

      // get timezone from browser
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const patientInfoWithTimezone = {
        ...patient,
        timeZone: timezone,
      };

      setConsumer(patientInfoWithTimezone);
      // await checkPatientProgram(patientInfoWithTimezone.id);

      if (patient.id) {
        isRegistered(true);

        if (referralCode?.includes("PSA-001")) {
          websocketLoggerService.sendMessage({
            externalUserId: patient.id,
            authenticationId: user.sub,
            eventType: `Navigate`,
            eventSubType: `Navigate to PSA-registration-successful, referralCode: ${referralCode}`,
            eventData: "",
          });
          navigate("/PSA-registration-successful", {
            state: {
              isAlreadyRegistered: true,
            },
          });
        } else {
          // setStep(1);
        }
      }

      return patient;
    };
    returnPatient();
  }, [isRegistered, navigate, referralCode, setConsumer, user]);

  const getServiceConsumerByEmail = async (patientEmail: string) => {
    if (!patientEmail) return;

    const patientEmailEncoded = patientEmail.replaceAll("+", "%2B");

    try {
      const response: IPatientInfo = await networkService.get(
        `/api/patients?email=${patientEmailEncoded}`
      );

      // console.log(response);
      websocketLoggerService.sendMessage({
        externalUserId: response.id,
        authenticationId: user?.sub,
        eventType: `API request: Get patient by phone number, PUT /api/patients/phone`,
        eventSubType: `check if patient exists in EHR, referralCode: ${referralCode}`,
        eventData: "patient exists in EHR",
      });

      return response;
    } catch (error) {
      console.log(error);
      console.log(error);
      websocketLoggerService.sendMessage({
        externalUserId: null,
        authenticationId: user?.sub,
        eventType: `API request: Get patient by phone number, PUT /api/patients/phone`,
        eventSubType: `check if patient exists in EHR, referralCode: ${referralCode}`,
        eventData: "patient does not exist in EHR",
      });
      return {
        id: null,
        firstName: null,
        lastName: null,
        middleName: null,
        gender: null,
        birthDate: null,
        addressLine1: null,
        addressLine2: null,
        zipCode: null,
        mobileNumber: user.phone_number,
        email: null,
        regionName: null,
        territory: null,
        dependants: [],
      };
    }
  };

  const createTask = async ({
    taskName,
    patientData,
  }: {
    taskName: string;
    patientData: IServiceConsumer;
  }) => {
    console.log(consumer);
    await networkService.post(
      "https://opencare-api-development.azo.dev/api/care-tasks",
      {
        name: "Did you experince " + taskName + " today?",
        goal: "Symptom Management",
        assignedByUserId: "abcdefg",
        assigneeName: "Dr. Arvind",
        status: "ACTIVE",
        description: "Symptom Description",
        startDate: new Date().toISOString(),
        timesPerWeek: 5,
        endDate: "",
        careTaskSchedule: [
          {
            dayOfWeek: "ANY",
            startTime: new Date().toISOString(),
            endTime: new Date(
              new Date().getTime() + 7 * 24 * 60 * 60 * 1000
            ).toISOString(),
          },
        ],
        careTaskPatient: [
          {
            name: patientData.firstName + " " + patientData.lastName,
            email: patientData.email,
            patientId: patientData.id,
          },
        ],
      },
      {
        headers: {
          api_key: process.env.REACT_APP_API_KEY,
          organization_code: process.env.REACT_APP_ORGANIZATION_CODE,
        },
      }
    );
  };

  const handleSubmit = async (values, setSubmitting) => {
    const regionName = cdtMapping.referralcodes[referralCode]?.region;
    const territoryFromReferralCode =
      cdtMapping.referralcodes[referralCode]?.territory;
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const userDetails = {
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: null,
      gender: values.gender,
      birthDate: new Date(values.dateOfBirth),
      addressLine1: null,
      addressLine2: null,
      // city: null,
      // state: null,
      zipCode: null,
      country: null,
      mobileNumber: values.mobileNumber,
      email: values.email,
      territory: territoryFromReferralCode,
      // mrn: values.employeeId,
      department: values.department,
      regionName,
      timeZone:
        currentTimeZone === "Asia/Calcutta"
          ? "Asia/Singapore"
          : currentTimeZone,
      // attributes: JSON.stringify({
      //   program: values.program || "",
      // }),
    };

    console.log("userDetails", userDetails);

    try {
      const valuesToLog = [
        Date().toLocaleString(),
        values.firstName,
        values.lastName,
        values.dateOfBirth,
        values.gender,
        values.mobileNumber,
        values.email,
        consumer.id,
      ];

      // eslint-disable-next-line no-constant-condition
      if (consumer.id !== null) {
        if (responses.Symptoms) {
          console.log("responses.Symptoms", responses.Symptoms);

          const selectedSymptoms = responses.Symptoms;

          const apiRequests = selectedSymptoms.map(async (symptom) => {
            return await createTask({
              taskName: symptom,
              patientData: consumer,
            });
          });

          try {
            await Promise.all(apiRequests).then((res) => {
              setStep(2);
            });
          } catch (error) {
            console.error("Error in API requests:", error);
          }
        }
      } else {
        const newPatient: IServiceConsumer = await networkService.post(
          `/api/patients`,
          userDetails
        );

        websocketLoggerService.sendMessage({
          externalUserId: newPatient.id,
          authenticationId: user?.sub,
          eventType: "API request: POST Create new patients /api/patients",
          eventSubType: "Create new patient if user does not exist in EHR",
          eventData: "New patient created",
        });

        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const newPatientInfoWithTimezone = {
          ...newPatient,
          timeZone: timezone,
        };
        setConsumer(newPatientInfoWithTimezone);

        if (responses.Symptoms) {
          console.log("responses.Symptoms", responses.Symptoms);

          const selectedSymptoms = responses.Symptoms;

          const apiRequests = selectedSymptoms.map(async (symptom) => {
            return await createTask({
              taskName: symptom,
              patientData: newPatient,
            });
          });

          try {
            await Promise.all(apiRequests).then((res) => {
              setStep(2);
            });
          } catch (error) {
            console.error("Error in API requests:", error);
          }
        }
      }

      networkService
        .post(`/api/sheets-integration`, {
          organizationId: 98,
          spreadsheetId: "1lP_g2Mb7tYZcuwlbtZpWgrWwKLVDMrKQ6NrRAH6Ol4I",
          sheetName: "Sheet1",
          primaryKeyColumnIndex: 7,
          endingColumn: "AN",
          dataToStore: [valuesToLog],
        })
        .then((res) => {
          websocketLoggerService.sendMessage({
            externalUserId: consumer.id,
            authenticationId: user?.sub,
            eventType: `Excel sheet integration`,
            eventSubType: "web intake end",
            eventData: "Excel sheet integration request successful",
          });
        })
        .catch((err) => {
          websocketLoggerService.sendMessage({
            externalUserId: consumer.id,
            authenticationId: user?.sub,
            eventType: `Excel sheet integration`,
            eventSubType: "web intake end",
            eventData: "Excel sheet integration request failed",
          });
          console.log(err);
        });

      // // Create a care task for the patient for all Symptoms

      setStep(1);
    } catch (err) {
      setSubmitting(false);

      if (err.message.includes("email")) {
        setIsEmailUniqueError(true);
        toast.error(err.message);
        websocketLoggerService.sendMessage({
          externalUserId: null,
          authenticationId: user?.sub,
          eventType: "API request: Create new patients: POST /api/patients",
          eventSubType: `Error: ${JSON.stringify(err)}`,
          eventData: "Create new patient failed",
        });
      }
    }
  };

  const initialValues = {
    CorporateUser: isCorporateUser,
    firstName: consumer?.firstName || "",
    lastName: consumer?.lastName || "",
    employeeId: consumer?.mrn || "",
    department: "",
    dateOfBirth: moment.utc(consumer?.birthDate).format("YYYY-MM-DD") || "",
    gender: consumer?.gender || "",
    mobileNumber: consumer?.mobileNumber || "",
    email: user?.email || consumer?.email || "",
    program: "",
  };

  return (
    <div className="flex-[2] pb-10 pt-8 pr-32 pl-20 min-h-screen max-lg:w-full bg-[#ECEFFB] max-lg:px-5">
      <div className="mt-4">
        <h1 className="font-bold text-2xl">1. Provide Personal Information</h1>
        <main className="mt-6">
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values, setSubmitting);
            }}>
            {({ values, initialValues, setFieldValue, isSubmitting }) => (
              <Form className="flex flex-col gap-4">
                <section className={`flex flex-col gap-0`}>
                  <div className="flex gap-6 items-center max-sm:flex-col max-sm:w-full max-sm:gap-3">
                    <div className="flex-1 max-sm:w-full">
                      <label className="text-sm text-black/60">
                        First Name
                      </label>
                      <Field
                        name="firstName"
                        type="text"
                        className="w-full py-3 px-2 border border-gray-300 focus:border-primary-500 focus:text-primary-800 rounded-md mt-1"
                        placeholder="Enter First Name"
                      />
                      <div className="min-h-[25px] ">
                        <ErrorMessage name="firstName">
                          {(msg) => <FieldError message={msg} />}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="flex-1 max-sm:w-full">
                      <label className="text-sm text-black/60">Last Name</label>
                      <Field
                        name="lastName"
                        type="text"
                        className="w-full py-3 px-2 border border-gray-300 focus:border-primary-500 focus:text-primary-800 rounded-md mt-1"
                        placeholder="Enter Last Name"
                      />
                      <div className="min-h-[25px]">
                        <ErrorMessage name="lastName" className="min-h-[20px]">
                          {(msg) => <FieldError message={msg} />}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>

                  {isCorporateUser ? (
                    <div className="flex gap-6 items-center max-sm:flex-col max-sm:w-full max-sm:gap-3">
                      <div className="flex-1 max-sm:w-full">
                        <label className="text-sm text-black/60">
                          Employee ID
                        </label>
                        <Field
                          type="text"
                          name="employeeId"
                          placeholder="Enter Employee ID"
                          className="w-full py-3 border border-gray-300 rounded-md mt-1 "
                        />
                        <div className="min-h-[25px]">
                          <ErrorMessage name="employeeId">
                            {(msg) => <FieldError message={msg} />}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="flex-1 max-sm:w-full">
                        <label className="text-sm text-black/60">
                          Department
                        </label>
                        <Field
                          name="department"
                          type="text"
                          placeholder="Enter Department Name"
                          className="w-full block py-3 mt-1 max-sm:mt-0 border-gray-300 rounded-md disabled:bg-slate-100"></Field>
                        <div className="min-h-[25px]">
                          <ErrorMessage name="department">
                            {(msg) => <FieldError message={msg} />}
                          </ErrorMessage>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div>
                    <label className="text-sm text-black/60">
                      Personal Email Address
                    </label>
                    <Field
                      name="email"
                      type="email"
                      placeholder="Enter email"
                      disabled
                      onChange={(e) => {
                        setIsEmailUniqueError(false);
                        setFieldValue("email", e.target.value);
                      }}
                      className="w-full py-3 px-2 border bg-gray-50 cursor-not-allowed border-gray-300 rounded-md mt-1"
                    />
                    <div className="min-h-[25px]">
                      <ErrorMessage name="email">
                        {(msg) => <FieldError message={msg} />}
                      </ErrorMessage>
                      {isEmailUniqueError && (
                        <FieldError message="This email is already registered. Please contact admin at abc-healthadmin@sheareshealthcare.com.sg" />
                      )}
                    </div>
                  </div>
                  <div className="flex gap-6 items-center max-sm:flex-col max-sm:w-full max-sm:gap-3">
                    <div className="flex-1 max-sm:w-full">
                      <label className="text-sm text-black/60">
                        Mobile Number
                      </label>
                      <Field
                        name="mobileNumber"
                        type="tel"
                        placeholder="+65 0000 0000"
                        disabled={!!initialValues.mobileNumber}
                        onChange={(e) => {
                          setIsMobileUniqueError(false);
                          setFieldValue("mobileNumber", e.target.value);
                        }}
                        className="w-full py-3 px-2 border border-gray-300 rounded-md mt-1"
                      />
                      <div className="min-h-[25px]">
                        <ErrorMessage name="mobileNumber">
                          {(msg) => <FieldError message={msg} />}
                        </ErrorMessage>
                        {isMobileUniqueError && (
                          <FieldError message="This mobile is already registered. Please contact admin at abc-healthadmin@sheareshealthcare.com.sg" />
                        )}
                      </div>
                    </div>
                    <div className="flex-1 max-sm:w-full">
                      <p className="text-sm text-gray-500">Date of Birth</p>
                      <Field
                        type="date"
                        name="dateOfBirth"
                        placeholder="DD/MM/YYYY"
                        min={minDate}
                        max={maxDate}
                        // placeholder="Select Date of Birth"
                        className="w-full py-3 border border-gray-300 rounded-md mt-2 "
                      />
                      <div className="min-h-[25px]">
                        <ErrorMessage name="dateOfBirth">
                          {(msg) => <FieldError message={msg} />}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>

                  <div className="flex gap-6 items-center max-sm:flex-col max-sm:w-full max-sm:gap-3">
                    <div className="flex-1 w-1/2 max-sm:w-full">
                      <label className="text-sm text-black/60">Gender</label>
                      <Field
                        id="gender"
                        name="gender"
                        type="text"
                        placeholder="Select gender"
                        component="select"
                        className="w-full block py-3 mt-1 max-sm:mt-0 border-gray-300 rounded-md disabled:bg-slate-100">
                        <option className="text-gray-400" value="" disabled>
                          Select gender
                        </option>
                        <option value="MALE">Male</option>
                        <option value="FEMALE">Female</option>
                      </Field>
                      <div className="min-h-[25px]">
                        <ErrorMessage name="gender">
                          {(msg) => <FieldError message={msg} />}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="">
                  <p className="text-sm text-black/60 mb-3">
                    What symptoms would you like to track:
                  </p>
                  <div className="space-y-4">
                    {[
                      "Abdominal pain or discomfort",
                      "Bloating",
                      "Gas",
                      "Constipation",
                      "Diarrhea",
                      "Mucus in stool",
                      "Urgency to have a bowel movement",
                      "Feeling of incomplete evacuation",
                      "Changes in stool consistency",
                      "Nausea",
                    ].map((symptom) => (
                      <label className="flex items-center " key={symptom}>
                        <input
                          type="checkbox"
                          name="Symptoms"
                          className="mr-4 w-6 h-6 text-primary-800 bg-white border-abchealth-gray-500 rounded focus:ring-primary-800 focus:ring-2"
                          value={symptom}
                          onChange={(e) =>
                            handleResponseChange(
                              "Symptoms",
                              e.target.checked
                                ? [
                                    ...(responses.Symptoms || []),
                                    e.target.value,
                                  ]
                                : (responses.Symptoms || []).filter(
                                    (s) => s !== e.target.value
                                  )
                            )
                          }
                        />
                        {symptom}
                      </label>
                    ))}
                  </div>
                </div>

                <div className="border border-primary-800 bg-[#F4B3FE]/10 py-8 px-4 rounded">
                  <div>
                    By Selecting "I agree", you are confirming that you have
                    read and agreed to{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={
                        cdtMapping.referralcodes[referralCode]?.consentTermsLink
                      }
                      className="text-primary-800">
                      Abc Health™ Programme's consent terms
                    </a>{" "}
                  </div>
                  <label className="flex gap-4 mt-4">
                    <input
                      type="checkbox"
                      placeholder="Select Long term conditions"
                      onChange={(e) => {
                        setIsAgree(e.target.checked);
                      }}
                      className="w-6 h-6 text-primary-800 bg-white border-abchealth-gray-500 rounded focus:ring-primary-800 focus:ring-2"
                    />
                    <div>I agree</div>
                  </label>
                </div>

                <button
                  type="submit"
                  disabled={!isAgree}
                  className={`w-max px-12 py-3 mt-4 text-white rounded font-bold ${
                    isAgree ? "bg-[#222424]" : "bg-gray-400 cursor-not-allowed"
                  }`}>
                  {isSubmitting ? (
                    <div className="flex gap-2 items-center">
                      <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                      <div>Submitting...</div>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </Form>
            )}
          </Formik>
        </main>
      </div>
    </div>
  );
};
export default PatientInfoForm;
